// styles for project page

.project_intro p:first-of-type{
    font-weight: 550;
}

// removing margin home_info_pane; problem with @media and padding priorities
.project_header_pane { // page title, intro and summary
    padding-top: 20px;
    padding-bottom: 20px;
    padding-right: 15px;
    padding-left: 15px;
    margin-top: 180px;
}
/* (Pending approval)
@media (min-width: 1200px){
    .project_header_pane { // page title, intro and summary
        min-height: 263.85px;
    }

}
*/

.project_title { //how to differ first title from other titles? (seperate top margin)
    margin-top: 1rem;
    margin-bottom: 1rem;
}

.project_icon {
    margin-right: 15px;
    margin-bottom: 15px;
}

.project_substyle {
    width: 100%;
}
  
.project_icon-max {
    max-height: 100px;
    max-width: 40%;
}

.project_bolder{
    font-weight: 600;
}

.project_research{
    font-size: 0.875rem;
    margin-bottom: 48px;
}

.project_btn {
    margin-left: 20px;
    color: #1565c0;
    border: 1px solid #1565c0;
    border-radius: 3px;
    display: inline-block;
    padding: 6px 8px;
}